@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Montserrat:wght@400;500;600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Gill Sans", sans-serif;
  line-height: 1;
  font-weight: 400;
  color: #223045;
}

.container {
  display: flex;
  flex-direction: row;
}

/* select,
input {
  -webkit-appearance: none;
  margin-right: 0.4rem;
} */

.title {
  font-size: 4rem;
  /* text-decoration-color: #3066BE; */
}
.sub-title {
  font-size: 2.5rem;
  /* text-decoration-color: #3066BE; */
}

.card {
  flex: auto;
  padding: 1.8rem;
  margin: 1rem;
  row-gap: 1rem;
  border-radius: 0.5rem;
  box-shadow: 4px 8px 15px #888888;
  text-align: center;
  cursor: pointer;
}

.active {
  background-color: #3066be;
  color: #fff;
}

.inactive {
  background-color: #fff;
  color: #3066be;
}

.error {
  background: var(--clr-primary-10);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.error h1 {
  font-size: 10rem;
}

.error h3 {
  text-transform: none;
  margin-bottom: 1.8rem;
}

.page-100 {
  min-height: calc(100vh - 10rem);
  padding: 5rem 0;
}

.page {
  min-height: calc(100vh - (20vh - 10rem));
}

.btn-large {
  text-decoration: none;
  text-align: center;
  padding: 1.8rem 1.1rem;
  border-radius: 5px;
  font-size: 1.8rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.section {
  padding: 3rem 0;
}

.section-left {
  text-align: left;
}

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

.form-center {
  text-align: center;
}

.text-center {
  text-align: center;
}

table {
  width: 100%;
}

table thead th {
  border-bottom: 1px solid #000;
  text-align: left;
  font-size: 1.1rem;
}

table tbody td {
  border-bottom: 1px solid #e6e8e6;
  padding: 0.4rem;
}

table tbody tr:hover {
  background-color: #dfe2df;
}

.form-control {
  margin-bottom: 1.25rem;
}

.search-input {
  padding: 0.6rem;
  background: var(--clr-grey-10);
  border-radius: var(--radius);
  /* border-color: transparent; */
  border-color: #888888;
  letter-spacing: var(--spacing);
  min-width: 150px;
  font-size: 0.9rem;
}

.display-input {
  padding: 0.5rem;
  background: var(--clr-grey-10);
  border-radius: var(--radius);
  border-color: transparent;
  border-color: #888888;
  letter-spacing: var(--spacing);
  width: 100%;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.search-input::placeholder {
  text-transform: capitalize;
}

.btn-small {
  display: inline-block;
  margin: 0.25em 0 0 0.25em;
  padding: 1.6rem 0;
  text-transform: capitalize;
  border: none;
  border-bottom: 1px solid transparent;
  letter-spacing: var(--spacing);
  color: var(--clr-grey-5);
  font-size: 2.2rem;
  min-width: 200px;
  text-align: center;
  text-decoration: none;
  border-radius: 1rem;
}

.hot {
  background: #3066be;
  color: #fff;
  cursor: pointer;
}

.active {
  background: #3066be;
  color: #fff;
  cursor: pointer;
}

.inactive {
  background: #d4d8d4;
  color: #888;
  cursor: auto;
}

.cold {
  background: #d4d8d4;
  color: #1d201d;
  cursor: pointer;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
}

.form-center {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 5px;
}

.button-container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr 1fr;
  margin-top: 1rem;
}

label {
  margin-bottom: 10px;
}

/* Modern HTML form CSS */

.mod-form {
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 8px 4px rgba(0, 0, 0, 0.1);
}

.mod-label {
  font-size: 2.2rem;
  display: block;
  margin-bottom: 5px;
  color: #888;
}

.form-group {
  margin-bottom: 20px;
  margin-right: 10px;
}

.help-text {
  letter-spacing: 0.1rem;
  line-height: 2.1rem;
  font-size: 1.4rem;
}

.mod-checkbox {
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 1rem;
}

.mod-input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: none;
  background-color: #f7f7f7;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
  transition: background-color 0.2s ease;
  font-size: 2.2rem;
  -webkit-appearance: none;
  margin-right: 0.4rem;
}

.mod-input:focus {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.fld-error {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: none;
  background-color: #f7f7f7;
  transition: background-color 0.2s ease;
  font-size: 2.2rem;
  -webkit-appearance: none;
  margin-right: 0.4rem;
  box-shadow: 0 0 3px red;
}

.mod-btn {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #3f51b5;
  color: #fff;
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.mod-btn:focus {
  background-color: #2c3e50;
}

.mod-select {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: none;
  background-color: #f7f7f7;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
  transition: background-color 0.2s ease;
  -webkit-appearance: none;
  font-size: 1.8rem;
  -webkit-appearance: none;
  margin-right: 0.4rem;
}

.fldselect-err {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: none;
  background-color: #f7f7f7;
  /* box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset; */
  box-shadow: 0 0 3px red inset;
  transition: background-color 0.2s ease;
  -webkit-appearance: none;
  font-size: 1.8rem;
  -webkit-appearance: none;
  margin-right: 0.4rem;
}

.error-msg {
  padding: 0.5rem 0 0 0;
  color: red;
  font-size: 1.4rem;
}

.mod-select:focus {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mod-select option:first-child {
  color: #888;
}

.mod-select:invalid {
  color: #888;
}

.mod-select option[value=""] {
  display: none;
}

.top-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #3f51b5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.logo a {
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
}

.nav-links {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin-left: 20px;
  color: #fff;
}

.nav-links li a {
  font-size: 18px;
  text-decoration: none;
  color: #fff;
  transition: color 0.3s ease;
}

.nav-links li a:hover {
  color: #f2f2f2;
}

.toastBody {
  font-size: 1.6rem !important;
}

.success {
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 8px 4px rgba(0, 0, 0, 0.1);
  text-align: -webkit-center;
}

.success-title {
  font-size: 2.5rem;
  color: #006c13;
  /* text-decoration-color: #3066BE; */
}

.failure-title {
  font-size: 2.5rem;
  color: red;
  /* text-decoration-color: #3066BE; */
}

.status-title {
  font-size: 2.5rem;
  color: #3f51b5;
  /* text-decoration-color: #3066BE; */
}

.success-msg {
  font-size: 2rem;
  color: #1d201d;
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 2.4rem;
  /* text-decoration-color: #3066BE; */
}

.circle {
  position: relative;
  background: #8cc63e;
  border-radius: 50%;
  width: 75px;
  height: 75px;
}

.xcircle {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: red;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.status-circle {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: #3066be;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.checkmark {
  position: absolute;
  transform: rotate(45deg) translate(-50%, -50%);
  left: 35%;
  top: 45%;
  height: 25px;
  width: 12px;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
}

.spacer {
  margin-top: 40px;
}

.icon {
  font-size: 40px;
  font-weight: normal;
  color: white;
}

.progress-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-number {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.step-label {
  margin-top: 20px;
  font-size: 1.4rem;
}

.connector {
  flex-grow: 1;
  height: 2px;
  background-color: #ccc;
  margin: 0px 0px 35px;
}

.step.completed .step-success {
  background-color: #2ecc71;
  color: #fff;
}

.step.completed .step-failure {
  background-color: #ec4a3b;
  color: #fff;
}

.step.active .step-number {
  background-color: #3498db;
  color: #fff;
}

.step.active ~ .connector {
  background-color: #3498db;
  border: #3498db;
}

.step.completed ~ .connector {
  background-color: #2ecc71;
}

.logo {
  vertical-align: middle;
  border-style: none;
  overflow-clip-margin: content-box;
  overflow: clip;
  margin: 10px 10px 10px 0;
  /* height: 62px; */
  /* width: 60px; */
  height: 6.4rem;
}

.cap-center {
  position: relative;
  height: 100px;
}
